/* buttons */
@import './colors';
@import './typography';

.btn-settings {
  width: 100%;
  border: none;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  color: $text-btn;
  font-family: 'Gabarito', sans-serif;

  user-select: none;

  &:hover:not(:disabled) {
    filter: brightness(90%);
  }
}

.transparent-button {
  background: $eventThemeActionsBgColor;
  backdrop-filter: $rsvp-backdrop-filter;
  color: $eventThemeBodyFontColor;
  &:hover {
    background: $eventThemePrimaryColor;
    color: $eventThemePrimaryTextColor;
    cursor: pointer;
  }
}

.btn {
  height: 50px;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  box-sizing: border-box;

  @extend .btn-settings;

  &:disabled {
    opacity: 0.25;
  }
}

.btn-lg {
  font-size: 1.125rem;
  line-height: 1.44;
}

.btn-sm {
  height: 36px;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
}

.btn-outline {
  border: 1px solid;
}

.btn-pagination {
  height: 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 25px;
  margin: 0 5px;

  @extend .btn-settings;
}

.btn-primary {
  background: $brand-primary;
  color: $text-on-primary;

  &.pop-color {
    background: $eventThemePopColor !important;
    color: $eventThemePopTextColor !important;

    &:disabled {
      background: $border-color !important;
      color: $text-tertiary !important;
    }
  }

  &:disabled {
    background: $border-color;
    color: $text-tertiary;
    opacity: 1;
    cursor: default;
  }
}

.btn-primary-party-page {
  background: $brand-primary;
  color: $text-on-primary;
}

.btn-community {
  background: $community-body-color !important;
  color: $community-background-color !important;
  font-family: $primaryFontFamily;
  box-shadow: unset !important;
  text-transform: uppercase;
  width: 245px;
  font-size: 17px !important;
  line-height: 1.31 !important;
  font-weight: normal !important;
  margin: auto;
}

.dark-mode:not(.platform-eventpage):not(.platform-weddingpage)
  .btn-primary-party-page {
  background: $brand-secondary;
  border: 1px solid black;
  border-radius: 12px;
  margin-bottom: 2px;
  color: $text-on-primary;
  box-shadow: -1px 2.5px 0 rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;

  &.spinner {
    opacity: 0.75;
  }
}

.btn-primary-unselected {
  background-color: transparent;
  color: $text-dark !important;

  &:hover {
    background-color: $filter-selected-status !important;
  }
}

.btn-grey-very-light {
  background: $grey-very-light;
  color: $text-dark;
}

.dark-mode {
  .btn-grey-very-light.sso-button {
    background: none;
    border: 1px solid $text-dark;
  }
}

.btn-white {
  background: $white;
  color: $const-dark-color;
  border: 1px solid $grey-very-light;
}

.btn-white-primary {
  background: $white;
  color: $text-brand-primary;
}

.btn-black-outline {
  background: transparent;
  border: 1px solid $const-dark-color;
  color: $const-dark-color;

  transition: background-color 0.2s ease;

  &:hover {
    background: $white;
    filter: none !important;
  }
}

.btn-white-outline {
  background: transparent;
  border: 1px solid $white;
  color: $white;

  transition: background-color 0.2s ease;

  &:hover {
    background: $white;
    color: $brand-primary-real;
    filter: none !important;
  }

  > i {
    margin-right: 6px;
    font-size: 20px;
    line-height: 0.63;
    letter-spacing: 2px;
  }
}

.btn-primary-gradient {
  background-image: linear-gradient(
    255deg,
    $brand-primary 0%,
    $brand-secondary 98%
  );
}

.btn-primary-outline {
  background: transparent;
  border: 1px solid $text-brand-primary;
  color: $text-brand-primary;
  &.pop-color {
    border-color: $eventThemePopColor !important;
    color: $eventThemePopColor !important;
  }
}

.btn-danger-outline {
  background: transparent;
  border: 1px solid $danger;
  color: $danger;
}

.btn-primary-outline-party-page {
  background: $white;
  border: 0.5px solid $text-brand-primary;
  color: $text-brand-primary;
}

.dark-mode:not(.platform-eventpage):not(.platform-weddingpage)
  .btn-primary-outline-party-page {
  background: $brand-primary;
  border: 1px solid black;
  border-radius: 12px;
  margin-bottom: 2px;
  color: $text-on-primary;
  box-shadow: -1px 2.5px 0 rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
}

.btn-primary-text {
  background: transparent;
  border: 1px solid transparent;
  color: $brand-primary;

  &.pop-color {
    color: $eventThemePopColor;
  }
}

.btn-danger-text {
  background: transparent;
  border: 1px solid transparent;
  color: $extra-red;
}

.btn-danger {
  background: $extra-red;
  color: $text-on-primary;
}
.action-btn-container {
  width: 100%;
}

.text-btn {
  border: none;
  font-weight: normal;
  cursor: pointer;

  color: $body-color;
  font-family: 'Gabarito', sans-serif;

  font-size: 14px;
  line-height: 1.71;
  text-align: center;

  &:hover:not(:disabled) {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: 0.25;
  }
}

.btn-primary-weddingpage {
  font-size: 14px;
  font-weight: 500;
  line-height: 3.21;
  text-align: center;
  color: #637d7f;
  background-color: #ffd892;
  border-radius: 3px;
}

.btn-weddingpage-transparent {
  width: fit-content !important;
  min-width: 170px;
  height: 40px;
  background: transparent;
  color: $sectionBodyColor;
  border: 1px solid $sectionBodyColor;
  border-radius: 0;
  font-family: 'Bodoni Moda', serif;
  font-size: 14px;
  font-weight: 500;
}

.btn-weddingpage {
  width: fit-content !important;
  min-width: 170px;
  height: 40px;
  background: $sectionBodyColor;
  color: $sectionBackgroundColor;
  border-radius: 0;
  font-family: 'Bodoni Moda', serif;
  font-size: 14px;
  font-weight: 500;
}

.btn-invitation-transparent {
  width: fit-content;
  min-width: 180px;
  padding: 0 20px;
  height: 45px;
  background: transparent;
  color: $eventThemeBodyFontColor;
  border: 1px solid $eventThemeBodyFontColor;
  border-radius: 0;
  font-family: $primaryFontFamily;
  font-size: 13px;
  line-height: 3.46;
  letter-spacing: 1.86px;
  font-weight: normal;
  text-transform: uppercase;

  transition: 0.1s;

  &:hover {
    background: $eventThemeBodyFontColor !important;
    color: $eventThemeBackgroundColor;
    filter: none;
  }

  > i {
    margin-left: 5px;
    font-size: 14px;
  }
}

// co-host invitation buttons
.btn-primary-party-page-co-host {
  background: $brand-primary;
  color: $text-on-primary;
  font-size: 15px;
  letter-spacing: 1.6px;
}

.dark-mode:not(.platform-eventpage):not(.platform-weddingpage)
  .btn-primary-party-page-co-host {
  background: $brand-primary;
  border: 1px solid black;
  border-radius: 12px;
  margin-bottom: 2px;
  color: $text-on-primary;
  box-shadow: -1.3px 3.7px 0 rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;

  &.spinner {
    opacity: 0.75;
  }
}

.btn-primary-outline-party-page-co-host {
  background: $white;
  border: 0.5px solid $text-brand-primary;
  color: $text-brand-primary;
  font-size: 15px;
  letter-spacing: 1.6px;

  &:hover {
    transition: 0.2s ease;
    background: $brand-primary;
    color: $text-on-primary;
  }
}

.dark-mode:not(.platform-eventpage):not(.platform-weddingpage)
  .btn-primary-outline-party-page-co-host {
  background: $brand-secondary;
  border: 1px solid black;
  border-radius: 12px;
  margin-bottom: 2px;
  color: $text-on-primary;
  box-shadow: -1.3px 3.7px 0 rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
}

.spinner-container {
  position: absolute;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-more-actions {
  width: 35px !important;
  height: 38px;
  z-index: 2;
  position: absolute;
  top: 0px;
  right: -40px;
  color: $black;
}

//button[appLoadingButton] {
//  position: relative;
//}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.btn.spinner {
  color: transparent !important;
  opacity: 0.5;
}

@media screen and (max-width: 500px) {
  .action-btn-container {
    width: auto;
    position: fixed;
    bottom: 110px;
    left: 25px;
    right: 25px;
    max-width: 380px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    width: 100%;
  }
}
