@import 'scss/colors';
@import 'scss/typography';

.mat-mdc-dialog-content {
  max-height: unset !important;
}

.mdc-dialog__surface {
  background-color: $normalDialogBackgroundColor !important;
  border-radius: 12px;
  box-shadow: none !important;
}

.normal-dialog {
  .mdc-dialog__surface {
    border-radius: 12px;
    background-color: $normalDialogBackgroundColor !important;
    box-shadow: none !important;
  }

  .event-dialog-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .title {
    position: sticky;
    top: 0;
    padding: 10px 20px 10px;
    margin: 8px 0 10px 0;
    background-color: $normalDialogBackgroundColor;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 3.3px;
    color: $text-brand-primary;
    z-index: 2;
  }

  .dialog-title,
  .dialog-title-no-resize {
    font-size: 32px;
    text-align: center;
    color: $text-brand-primary;
    margin-bottom: 10px;
  }

  .message {
    text-align: center;
    font-size: 18px;
    line-height: 1.33;
  }
  .message-small {
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
  }
  .close-button {
    right: 15px;
  }
  .close-button,
  .go-back-button {
    position: absolute;
    top: 15px;
    font-size: 35px;
    color: $text-brand-primary;

    cursor: pointer;
  }

  .go-back-button {
    left: 15px;
  }

  .dialog-body {
    padding-bottom: 40px;
    color: $body-color;
  }

  .dialog-actions {
    display: flex;
    gap: 20px;
    z-index: 2;
    justify-content: center;
    align-items: center;

    .btn {
      max-width: 300px;
      font-size: 15px;
    }

    .btn-primary-outline {
      background: transparent;
    }
  }

  &.event-dialog {
    .mdc-dialog__surface {
      background-color: $normalDialogEventBackgroundColor !important;
    }

    .title {
      color: $eventThemePrimaryColor;
      background-color: $normalDialogEventBackgroundColor;
    }

    .dialog-title {
      color: $eventThemePrimaryColor;
    }

    .close-button {
      color: $eventThemePrimaryColor;
    }

    .dialog-body {
      color: $eventThemePrimaryColor;
    }

    .dialog-actions {
      .btn-primary {
        background-color: $eventThemePrimaryColor;
        color: $eventThemeActionColor;
      }

      .btn-primary-outline {
        background: transparent;
        border: 1px solid $eventThemePrimaryColor;
        color: $eventThemePrimaryColor;
      }
    }

    ::ng-deep {
      // mat-select
      .mat-mdc-select-value {
        color: $eventThemePrimaryColor !important;
      }
      .mat-mdc-select-arrow {
        color: $eventThemePrimaryColor !important;
      }
      .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled),
      .mdc-list-item__primary-text {
        color: $eventThemeBrandColor !important;
      }
    }

    .tabs-dialog {
      .mat-mdc-tab .mdc-tab__content {
        background: $eventThemeSecondaryColor !important;
      }
    }
  }

  &.rsvp-dialog {
    .mdc-dialog__surface {
      background-color: $bg !important;
    }

    .title {
      color: $text-primary;
      background-color: $bg;
    }

    .resend-wrapper {
      .text-description {
        color: $text-primary;
      }
    }

    .subheader {
      color: $text-primary;
    }

    .dialog-title {
      color: $text-primary;
    }

    .resend-code-cooldown-info {
      color: $text-primary;
    }

    @media only screen and (max-width: 600px) {
      .dialog-title {
        background-color: $bg !important;
        margin-bottom: 10px;
      }
    }

    .close-button {
      color: $text-primary;
    }

    .dialog-body {
      color: $eventThemeRsvpPrimaryColor;
    }

    .dialog-actions {
      .btn-primary {
        background-color: $eventThemeRsvpPrimaryColor;
        color: $eventThemeRsvpPrimaryTextColor;
      }

      .btn-primary-outline {
        background: transparent;
        border: 1px solid $eventThemeRsvpPrimaryColor;
        color: $eventThemeRsvpPrimaryColor;
      }
    }
  }

  &.opacity-dialog {
    .mdc-dialog__surface {
      background-color: $opacity-dialog !important;
      backdrop-filter: $rsvpDialogBackdropFilter;
    }

    .title {
      color: $text-brand-primary;
      background-color: transparent;
    }

    .resend-wrapper {
      .text-description {
        color: $text-brand-primary;
      }
    }

    .subheader {
      color: $text-brand-primary;
    }

    .dialog-title {
      color: $text-brand-primary;
    }

    @media only screen and (max-width: 600px) {
      .dialog-title {
        background-color: transparent !important;
        margin-bottom: 20px;
      }
    }

    .close-button {
      color: $text-brand-primary;
    }

    .dialog-body {
      color: $text-brand-primary;
    }
  }

  &.generic-dialog-alpha {
    .mdc-dialog__surface {
      background-color: $background-color-alpha !important;
      backdrop-filter: $backdrop-filter-alpha !important;
    }
    .title {
      color: $body-color;
      background-color: transparent;
      font-family: Gabarito;
      padding: 0;
      font-size: 18.6px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      z-index: auto;
    }
  }

  &.secondary-dialog {
    .mdc-dialog__surface {
      background-color: $bg-secondary !important;
      border-radius: 12px;
      border: 1px solid $border-color;
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05) !important;
    }

    .dialog-header {
      display: flex;
      height: 58px;
      padding: 0px 1rem;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      background-color: $bg-secondary;

      position: sticky;
      top: 0;
      z-index: 1;
    }

    .close-button-custom {
      position: sticky;
      top: 1rem;
      right: 1rem;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      color: $text-primary;

      > i {
        cursor: pointer;
      }
    }

    .dialog-title-custom {
      @extend .h4;
    }

    .dialog-body {
      padding: 0 1rem 1rem;
    }

    .dialog-actions {
      padding: 1rem;
      justify-content: flex-end;
      background-color: $bg-secondary;

      position: sticky;
      bottom: 0;
      z-index: 1;

      box-sizing: border-box;

      .btn {
        width: auto;
        padding: 0 40px;
      }
    }
  }

  &.new-normal-dialog {
    .mdc-dialog__surface {
      background-color: $bg !important;
      border-radius: 12px;
      border: 1px solid $border-color;
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.05) !important;
    }

    .dialog-header {
      display: flex;
      height: 58px;
      padding: 0px 1rem;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      background-color: $bg;

      position: sticky;
      top: 0;
      z-index: 2;
    }

    .close-button-custom {
      position: sticky;
      top: 1rem;
      right: 1rem;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      color: $text-brand-primary;

      > i {
        cursor: pointer;
      }
    }

    .dialog-title-custom {
      @extend .h4;
    }

    .dialog-body {
      padding: 0 1rem 1rem;
    }

    .dialog-actions {
      padding: 1rem;
      justify-content: flex-end;
      background-color: $bg;

      position: sticky;
      bottom: 0;
      z-index: 1;

      box-sizing: border-box;

      .btn {
        width: auto;
        padding: 0 40px;
      }
    }
  }

  &.book-event-slot-dialog {
    .mdc-dialog__surface {
      background-color: transparent !important;
      backdrop-filter: unset !important;
      border: unset !important;
      box-shadow: none !important;
    }
  }
}

.normal-dialog,
.section-event-rsvp-dialog {
  mat-form-field.ng-invalid.ng-touched {
    animation: none;
  }

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0 !important;

    .mat-mdc-form-field-error {
      font-size: 13px;
      line-height: 2;
    }
  }

  .mat-error-outside {
    margin-top: -20px;
  }

  .flex-inputs-cont {
    display: flex;
    column-gap: 20px;
    margin-bottom: 20px;
  }

  .select-or-add-cont {
    display: flex;
    align-items: center;
  }

  .flex-input {
    width: auto;
    padding: 0;

    &.mat-mdc-form-field {
      margin: 0;

      &.ng-invalid.ng-touched {
        animation: none;
      }
    }

    .mat-mdc-text-field-wrapper {
      height: 50px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;
    }
  }

  .flex-half {
    flex: 1 0 50%;
  }

  .flex-half-shrink {
    flex: 1 1 50%;
  }

  .half-field {
    width: calc(50% - 10px);
  }

  .file-container {
    margin-bottom: 20px;
  }

  .template-asset-select-cont {
    margin-bottom: 20px;
  }

  app-template-asset-select .image-preview-component {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .normal-dialog {
    &.generic-dialog-alpha {
      .mdc-dialog__surface {
        background-color: $body-bg !important;
        backdrop-filter: unset !important;
      }
    }
  }
  .normal-dialog,
  .section-event-rsvp-dialog {
    .flex-inputs-cont {
      flex-direction: column;
      margin-bottom: 0;
    }
    .flex-input {
      &.mat-mdc-form-field {
        margin-bottom: 20px;
      }
    }

    .half-field {
      width: 100%;
    }
  }
}

.viewer-dialog {
  &.transparent-viewer-dialog {
    .mdc-dialog__surface {
      background-color: transparent !important;
    }
  }

  .mdc-dialog__surface {
    border-radius: 12px !important;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $black;
    background-color: $white;
    border-radius: 50%;
    padding: 4px 8px;
    cursor: pointer;
  }
}

.event-after-dialog {
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 35px;
    color: $text-brand-primary;

    cursor: pointer;
  }

  .after-dialog-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 20px 45px 20px;
    background-color: $custom-dialog-bg;

    .title {
      font-size: 48px;
      line-height: 1.5;
      text-align: center;
      color: $text-brand-primary;
      margin-bottom: 6px;
    }

    .title-desc {
      font-size: 20px;
      line-height: 1.44;
      margin-bottom: 26px;
      text-align: center;
    }
  }

  .after-dialog-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;

    &.insurance-check-body {
      background-color: $body-bg !important;

      .body-header {
        line-height: 1.25;
        margin-bottom: 18px;
        color: $brand-secondary !important;
      }

      .subtitle {
        width: 100%;
        max-width: 420px;
        box-sizing: border-box;
        font-size: 18px;
        line-height: 1.33;
        color: $body-color !important;
      }

      .share-options {
        width: 100%;
        max-width: 420px;
      }
    }

    .affiliate-list {
      display: flex;
      gap: 20px;
      overflow-x: auto;
      overflow-y: hidden;
      box-sizing: border-box;
    }
  }

  .after-footer {
    .main-footer {
      background-color: $internal-body-bg;
    }
  }

  .body-header {
    font-size: 30px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    color: $text-brand-primary;
    margin-top: 50px;
    margin-bottom: 5px;
    padding: 0 20px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 20px;
  }
  .action-btn {
    width: 100%;
    max-width: 280px;
    margin-top: 30px;

    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .event-after-dialog {
    .after-dialog-header {
      padding: 50px 20px 30px 20px;

      .title {
        font-size: 32px;
        line-height: 1.25;
        margin-bottom: 5px;
      }

      .title-desc {
        font-size: 18px;
        line-height: 1.44;
        margin-bottom: 25px;
      }
    }

    .after-dialog-body {
      .affiliate-list {
        width: 100%;
        padding: 0 40px;
        box-sizing: border-box;
      }
    }

    .body-header {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .normal-dialog {
    height: 100% !important;

    .mdc-dialog__surface {
      border-radius: unset !important;
    }

    .event-dialog-content {
      padding: 20px !important;
      padding-top: 0 !important;
    }

    .close-button,
    .go-back-button {
      position: fixed;
      font-size: 30px;
      z-index: 3;
    }

    .dialog-title {
      position: sticky;
      top: 0;
      padding: 20px 0 20px;
      margin: 0;
      background-color: $normalDialogBackgroundColor;
      font-size: 18px;
      line-height: 1.33;
      z-index: 2;
    }

    .dialog-actions {
      position: sticky;
      width: 100%;
      bottom: 0;
      flex-direction: column-reverse;
      gap: 15px;

      .btn {
        width: 100%;
        font-size: 15px;
      }
    }

    &.event-dialog {
      .dialog-title {
        background-color: $normalDialogEventBackgroundColor;
      }
    }

    &.rsvp-dialog {
      .dialog-title {
        background-color: $rsvpDialogBackgroundColor;
      }
    }
  }

  .section-event-rsvp-dialog {
    height: 100% !important;

    .mdc-dialog__surface {
      border-radius: unset !important;
    }

    .event-dialog-content {
      padding: 20px !important;
      padding-top: 0 !important;
    }

    .close-button,
    .go-back-button {
      position: fixed !important;
      top: 18px !important;
      font-size: 30px !important;
      z-index: 3;
    }

    .dialog-title {
      position: sticky;
      top: 0;
      padding: 20px 0 20px;
      margin: 0;
      background-color: $eventThemeBackgroundColor;
      font-size: 28px !important;
      z-index: 2;
    }

    .dialog-actions {
      position: sticky;
      width: 100%;
      bottom: 0;
      flex-direction: column;
      gap: 15px;

      .btn {
        width: 100%;
      }
    }
  }
}

.section-event-rsvp-dialog {
  .mdc-dialog__surface {
    border: solid 0.5px $eventThemePrimaryColor;
    border-radius: unset !important;
    background-color: $eventThemeBackgroundColor !important;
    box-shadow: none !important;
  }

  .event-dialog-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .dialog-title,
  .dialog-title-no-resize {
    font-size: 44px;
    font-weight: bold;
    line-height: 1.02;
    text-align: center;
    color: $eventThemePrimaryColor !important;
    margin-bottom: 40px;
  }

  .close-button {
    right: 15px;
  }
  .close-button,
  .go-back-button {
    position: absolute;
    top: 15px;
    font-size: 35px;
    color: $eventThemePrimaryColor !important;

    cursor: pointer;
  }

  .go-back-button {
    left: 15px;
  }

  .dialog-body {
    color: $eventThemeBodyFontColor !important;
  }

  .dialog-form {
    margin-bottom: 48px;
  }

  .dialog-actions {
    display: flex;
    gap: 20px;
    z-index: 2;
    justify-content: center;
    align-items: center;

    .btn {
      max-width: 300px;

      &.btn-invitation-transparent {
        &:not(:hover) {
          background: transparent !important;
          color: $eventThemeBodyFontColor !important;
        }
      }
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
    padding-left: 4px;

    .mat-mdc-form-field-infix {
      min-height: 45px;
      color: $eventThemeBodyFontColor;

      mat-label {
        color: $eventThemeBodyFontColor;
      }
    }

    .mdc-text-field__input {
      color: $eventThemeBodyFontColor;
    }

    .mdc-line-ripple::before {
      border-bottom-color: $eventThemeBodyFontColor !important;
    }

    &.mdc-text-field--invalid {
      .mdc-line-ripple::before {
        border-bottom-color: $danger !important;
      }
    }
  }
  .mat-focused .mdc-text-field--filled,
  .mat-mdc-form-field.has-value .mdc-text-field--filled {
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }
  }

  // mat-select
  .mat-mdc-select-value {
    color: $eventThemeBodyFontColor !important;
  }
  .mat-mdc-select-arrow {
    color: $eventThemeBodyFontColor !important;
  }

  .iti__tel-input {
    color: $eventThemeBodyFontColor;

    &::-webkit-input-placeholder {
      color: rgb(150, 150, 150);
    }
  }

  .iti .iti__flag-container .iti__selected-flag .iti_custom_arrow i {
    color: $eventThemeBodyFontColor;
  }
}

// event action dialog
.event-action-dialog {
  position: fixed;
  left: 184px;
  z-index: 4;
  background-color: $body-bg;
  height: 100%;
  width: 251px;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 70px;
  border-top: 1px solid $border-color;

  &.short-view {
    overflow-y: hidden;
    padding-bottom: 0;

    .search-box-wrapper {
      display: none;
    }

    .selection-list {
      padding: 0 10px;
    }
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    z-index: 3;
    color: $text-brand-primary;
    cursor: pointer;
  }

  .dialog-header {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .dialog-title {
    position: sticky;
    top: 0;
    padding: 10px 0 9px;
    margin: 0;
    background-color: $body-bg;
    color: $text-brand-primary;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    z-index: 2;
  }

  .search-box-wrapper,
  .search-box {
    width: 100%;
    min-width: unset !important;
  }

  .search-box {
    height: 30px !important;
    padding-left: 28px !important;
    font-size: 12px !important;

    &::placeholder {
      font-size: 12px !important;
    }
  }

  .search-icon,
  .x-mark-icon {
    font-size: 12px !important;
  }

  .category-tabs {
    margin-top: 10px;

    .mat-mdc-tab-header {
      border-bottom: 0.3px solid $text-dark;
    }
    .mat-mdc-tab-labels {
      gap: 10px;
    }
    .mat-mdc-tab {
      min-width: unset;
      height: 20px;
      padding-right: 2px;
      padding-left: 2px;
      font-size: 12px;
      line-height: 1.2;

      &.mdc-tab--active .mdc-tab__text-label {
        color: $text-brand-primary !important;
      }
      .mdc-tab__text-label {
        color: $text-dark !important;
      }
      .mdc-tab-indicator__content--underline {
        border-color: $text-brand-primary;
      }
    }
  }

  .selection-list {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
  }

  .toggle-container {
    width: 100%;
    background-color: $bg-tertiary;
    //  border: 4px solid $bg-tertiary !important;
    gap: 5px;
    box-sizing: border-box;
    margin-bottom: 18px;

    .toggle-button {
      //  padding: 5px;
      font-size: 12px;
    }
  }
}

.dialog-blur-backdrop {
  background-color: $dialog-backdrop-bg;
  backdrop-filter: blur(50px);
}

.secondary-dialog-blur-backdrop {
  background: rgba(255, 255, 255, 0.8);

  backdrop-filter: blur(12px);
}

.bottom-sheet-backdrop {
  background-color: unset;
}

@media only screen and (max-width: 768px) {
  .event-action-dialog {
    left: 0;
    width: 100%;
    z-index: 3;

    .dialog-title {
      padding: 15px 0;
      font-size: 16px;
      line-height: 1.5;
    }

    .close-button {
      position: fixed;
      top: 16px;
      right: 18px;
      font-size: 20px;
    }

    .category-tabs {
      margin-top: 12px;

      .mat-mdc-tab-labels {
        gap: 12px;
      }
      .mat-mdc-tab {
        min-width: unset;
        height: 30px;
        padding-right: 2px;
        padding-left: 2px;
        font-size: 16px;
        line-height: 1.33;
      }
    }
  }
}

::ng-deep .event-dialog {
  ::ng-deep .tabs-dialog {
    ::ng-deep .mat-mdc-tab .mdc-tab__content {
      border-radius: 20px;
      padding: 2px;
    }
  }
}

.bottom-sheet-dialog {
  .mat-bottom-sheet-container {
    padding: 10px 25px 16px;
    background-color: $bottom-sheet-dialog-bg;
    backdrop-filter: blur(50px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px 12px 0 0;
  }
}
